import axios from 'axios';
import storage from '@domatic/local-storage';

import { getQueryClient, useQuery, useMutation } from './queryClient';
import { EventEmitter } from 'events';

export const errorCheck = error => {
    if (error?.response?.status === 401) {
        console.log('401 error...ending session');
        endSession(error);
    }
    else throw error;
};

/*  Session Storage
    expiresAt: 1618754585011
    isAdmin: true
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlZmFiYWQwYWQzZDc0MzRlZDdkNzM0ZiIsImlzQWRtaW4iOnRydWUsInVzZXJuYW1lIjoiamltIiwiaWF0IjoxNjE4NjY4MTg1LCJleHAiOjE2MTg3NTQ1ODV9.xJ86gTmLxivv_GUnIxvA9Ft-mQ7b5pUZP2HDrRB_FTg"
    userId: "5efabad0ad3d7434ed7d734f"
    warningAt: 1618754525011
*/

// manage session object in local storage

export const sessionEvents = new EventEmitter();

let _sessionStore = { pending: true };

const getSessionStore = () => _sessionStore;

const setSessionStore = async session => {
    _sessionStore = session;
    axios.defaults.headers.common['Authorization'] = `Bearer ${session.token}`;
    const queryClient = getQueryClient();
    queryClient.invalidateQueries(['session']);
    await storage.setItem('user-session', JSON.stringify(session));
};

const clearSession = async () => {
    _sessionStore = null;
    delete axios.defaults.headers.common['Authorization'];
    const queryClient = getQueryClient();
    queryClient.invalidateQueries(['session']);
    await storage.removeItem('user-session');
    sessionEvents.emit('close');
};

const _startup = async () => {
    try {
        const value = await storage.getItem('user-session');
        _sessionStore = JSON.parse(value);

        if (_sessionStore) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${_sessionStore.token}`;
            console.log('[session _startup] found existing session:', _sessionStore);
            await _startSessionTimer();
        }
        const queryClient = getQueryClient();
        queryClient.invalidateQueries(['session']);
    }
    catch (error) {
        console.error('[session _startup] error reading session from local storage:', error);
        _sessionStore = null;
        const queryClient = getQueryClient();
        queryClient.invalidateQueries(['session']);
    }
};

_startup();

// process session information periodically to detect expiration

let _sessionStatus = {};

const _getSessionStatus = async () => {
    const now = Date.now();
    const sessionStore = getSessionStore();
    const userId = sessionStore?.userId;
    const isAdmin = !!sessionStore?.isAdmin;
    const pending = !!sessionStore?.pending;
    const expiresAt = sessionStore?.expiresAt || 0;
    const expired = !sessionStore || now >= expiresAt;
    const warningAt = sessionStore?.warningAt || 0;
    const warning = !!sessionStore && !expired && now > warningAt;
    const roles = sessionStore?.roles || [];

    const sessionStatus = {
        userId,
        isAdmin,
        pending,
        expiresAt,
        warning,
        expired,
        roles,
        token: sessionStore?.token
    };
    const updated = JSON.stringify(_sessionStatus) !== JSON.stringify(sessionStatus);
    _sessionStatus = sessionStatus;
    if (updated && expired) {
        sessionEvents.emit('close');
    } else if (updated && !expired) {
        sessionEvents.emit('open');
    }
    return { ...sessionStatus, updated };
};

let _sessionTimer = null;

export const startSession = async session => {
    clearTimeout(_sessionTimer);
    _sessionTimer = null;
    console.log('[session startSession] new session, expires at', new Date(session?.expiresAt));
    setSessionStore(session);
    return _startSessionTimer();
};

export const endSession = error => {
    if (error) {
        console.warn('[session endSession] ending session due to error:', error.message);
    }
    else {
        console.log('[session endSession] ending session, no error.');
    }
    if (_sessionTimer) {
        clearTimeout(_sessionTimer);
        _sessionTimer = null;
    }
    clearSession();
};

const _startSessionTimer = () => _sessionCheck();

const _sessionCheck = async () => {
    const session = await _getSessionStatus();
    const queryClient = getQueryClient();
    if (session.updated) {
        setTimeout(() => queryClient.invalidateQueries(['session']), 0);
    }
    // console.log('[session _sessionCheck] session:', session);
    if (session?.expired) {
        endSession(new Error('[session _sessionCheck] session timed out.'));
    }
    else {
        _sessionTimer = setTimeout(_sessionCheck, 5000);
    }
};

// hooks functions for components to access session state

export const useSession = () => {
    const { data: session } = useQuery({
        queryKey: ['session'],
        queryFn: _getSessionStatus,
        staleTime: Infinity
    });
    return session;
};

export const useSessionPending = () => {
    const session = useSession();
    return !session || session?.pending;
};

export const useUserId = () => {
    const session = useSession();
    return session?.userId;
};

export const useAuthenticated = () => {
    const session = useSession();

    return !!(session && !(session?.expired));
};

let _adminLurking = false;

export const useActualAdmin = () => {
    const session = useSession();
    return !!(session?.isAdmin);
};

export const useLurking = () => {
    const { data: lurking } = useQuery({
        queryKey: ['lurking'],
        queryFn: async () => _adminLurking,
        staleTime: Infinity
    });
    return lurking;
};

export const useLurk = () => {
    const isAdmin = useActualAdmin();
    const queryClient = getQueryClient();
    const { mutate } = useMutation(
        async value => _adminLurking = isAdmin && value,
        {
            onSuccess: () =>
                queryClient.invalidateQueries({
                    queryKey: ['lurking']
                })
        }
    );
    return mutate;
};

export const useAdmin = () => {
    const isAdmin = useActualAdmin();
    const lurking = useLurking();
    return isAdmin && !lurking;
};

export const useRole = role => {
    const session = useSession();
    const roles = session?.roles || [];
    return roles.indexOf(role) >= 0;
};

export const getUserId = () => {
    return _sessionStore?.userId;
};
